.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
h1 {
  color: white;
  font-weight: 500;
  text-align: center;
  margin: 60px 0;
}

.container {
  width: 960px;
  margin: 0 auto;
}

.tabs {
  display: flex;
  justify-content: space-between;
}

.tabs button {
  background-color: lightgray;
  border: none;
  color: #888888;
  cursor: pointer;
  padding: 25px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.5s ease-out;
}

.tabs button:hover {
  color: rgb(98, 13, 227);
  background-color: rgba(255, 255, 255, 0.15);
}

.tabs button:disabled {
  background: linear-gradient(245.39deg, #FC65ED -35.14%, #0500FF 143.06%);
  color: white;
}

.title {
  color: black;
  font-size: medium;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

.content {
  padding: 50px 80px;
  background-color: white;
  font-weight: 300;
  line-height: 30px;
  font-size: 16px;
  text-align: justify;
}

.content,
button {
  font-size: 15px;
}

footer{
  display: flex;
  justify-content: center;
  text-decoration: none;
  margin: 50px 0;
}

footer a {
  color: #999999;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 960px) {

  .container {
      width: 100%;
  }

  .content {
      padding: 50px;
  }
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
.pasifMusteri {
  background-color: #bd0b0b;
  color: white;
}
/* Add these styles to your existing stylesheet or in a style tag in your HTML file */

.form-check {
  display: flex;
  align-items: center; /* Align items vertically in the center */
}

.form-check-input {
  margin-right: 5px; /* Adjust the margin as needed */
}
.dataTables_paginate{
  margin-left: 300px;
}
